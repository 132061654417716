<template>
  <div class="menu text-center">
    <h2>Opnunarmatseðill</h2>
    <img src="../assets/menu.png" alt=""><br>
    <img src="../assets/drink.png" alt=""><br>
    <img src="../assets/wine.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.menu {
  padding-top: 40px;
  background-color: #262526;
  color: #ffffff;
}
.menu img {
  max-width: 100%;
  padding: 30px;
}
@media only screen and (max-width: 767px) {
.menu {
  padding-top: 100px;
}

}
</style>